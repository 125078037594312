import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Trans } from "react-i18next";
import ItemsService from "../services/items.service.js";
import Spinner from "../shared/Spinner";
import "../styles/inputs.scss";
import itemService from "../services/item.service.js";
import i18n from "../../i18n.js";

const ItemToPrint = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemClasses, setItemClasses] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [FilteredItemTypes, setFilteredItemTypes] = useState([]);
  const [selectedItemClass, setSelectedItemClass] = useState(1);
  const [selectedItemType, setSelectedItemType] = useState(1);

  function getItemTypes(itemClassId) {
    setIsLoading(true);
    ItemsService.getItemTypes()
      .then((res) => {
        setItemTypes(res.data);
        const filteredItemTypes = res.data.filter(
          (item) => item.itemclass === itemClassId
        );
        setFilteredItemTypes(filteredItemTypes);
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("There is a problem loading data");
        setIsLoading(false);
      });
  }

  function getItemClasses() {
    setIsLoading(true);
    ItemsService.getItemClasses()
      .then((res) => {
        const data = res.data.filter((item) => item.active === true);
        setItemClasses(data);
        setSelectedItemClass(data[0].id);
        getItemTypes(data[0].id);
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("There is a problem loading data");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getItemClasses();
  }, []);

  useEffect(() => {
    if (selectedItemClass && itemTypes && itemTypes.length > 0) {
      const filteredItemTypes = itemTypes?.filter((item) => {
        return item.itemclass === parseInt(selectedItemClass);
      });
      setSelectedItemType(filteredItemTypes[0]?.id);
      setFilteredItemTypes(filteredItemTypes);
    }
  }, [selectedItemClass]);

  const downloadHandler = () => {
    if (selectedItemClass && selectedItemType) {
      setIsLoading(true);
      itemService
        .downloadNewItemForm(i18n.language, selectedItemClass, selectedItemType)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "newItemForm.docx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className='container mb-5'>
            <div className='row'>
              <h3 className='col-md-2 mb-0 d-flex justify-content-center align-items-center'>
                <Trans>Item class</Trans>:
              </h3>
              <select
                name='itemclass'
                className='col-md-3 d-flex justify-content-center'
                onChange={(e) => {
                  setSelectedItemClass(e.target.value);
                }}
                value={selectedItemClass}>
                {itemClasses.map((itemClass) => (
                  <option key={itemClass.id} value={itemClass.id}>
                    {itemClass.title}
                  </option>
                ))}
              </select>
              <h3 className='col-md-2 mb-0 d-flex justify-content-center align-items-center'>
                <Trans>Items category</Trans>:
              </h3>
              <select
                name='itemtype'
                className='col-md-3 d-flex justify-content-center'
                onChange={(e) => {
                  setSelectedItemType(e.target.value);
                }}
                value={selectedItemType}>
                {FilteredItemTypes?.map((itemType) => (
                  <option key={itemType.id} value={itemType.id}>
                    {itemType.title}
                  </option>
                ))}
              </select>
              <div className='col-md-2 mr-0 d-flex justify-content-center'>
                <button onClick={downloadHandler}>
                  <Trans>Download new item form</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ItemToPrint;
