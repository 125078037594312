import ApiManager from "./axios-config";
import authHeader from "./auth-header";
import URL from "./APIURL";
const API_URL = URL + "/languages/";

class HRService {
  getTranslations() {
    return ApiManager.get(API_URL, {
      headers: { Authorization: authHeader() },
    });
  }
  getAllLanguageEnum() {
    return ApiManager.get(API_URL + "translation?all=true", {
      headers: { Authorization: authHeader() },
    });
  }
  getTranslationsByQuery(query) {
    return ApiManager.get(API_URL + query, {
      headers: { Authorization: authHeader() },
    });
  }
  putTranslations(data) {
    return ApiManager.put(API_URL, data, {
      headers: { Authorization: authHeader() },
    });
  }
  postTranslations(data) {
    return ApiManager.post(API_URL, data, {
      headers: { Authorization: authHeader() },
    });
  }
  postNewLanguage(data) {
    return ApiManager.post(API_URL + "translation", data, {
      headers: { Authorization: authHeader() },
    });
  }
}

export default new HRService();
