export const getCountyFlagIconUrl = (countryCode) => {
  if (!countryCode) return "";
  // if (!getCountryFlagIcon(countryName)) {
  //   return "";
  // }
  // const url = `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`;
  const url = `${process.env.PUBLIC_URL}/assets/images/countryIcons/${countryCode}.svg`;

  return url;
};
