import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import ItemToPrint from "./ItemToPrint";
import "./print.scss";

const PrintItem = () => {
  const [loading, setLoading] = React.useState(false);

  return (
    <div className='print-page'>
      <ItemToPrint />
    </div>
  );
};

export default PrintItem;
